.ant-input {
    height: 38px;
    border-radius: 4px!important;
}

.ant-input-affix-wrapper, .ant-input-number {
    /*border: 1px solid rgba(227,227,227,1)!important;*/
    border-radius: 4px;
    width: 100%!important;
}

.ant-input[disabled] {
    background: #F4F4F4!important;
    border-color: #F4F4F4!important;
}

/*编码式环境*/
.codeName .ant-input{
    height: 47px!important;
}
