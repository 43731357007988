/*悬浮卡片*/
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
    padding-left: 0!important;
}

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
    padding-right: 0!important;
}

.ant-popover-inner {
    border-radius: 16px;
}
/*个人中心*/
.person-popover .ant-popover-inner-content {
    padding: 0!important;
    background: unset!important;
    border-radius: 16px!important;
}

.person-popover .ant-popover-arrow {
    display: none!important;
}

.person-popover .ant-popover-content {
    top: -20px!important;
    right: 6px!important;
    background: unset!important;
    border-radius: 16px!important;
}

.person-popover .ant-popover-inner {
    box-shadow: unset!important;
}

/*离线数据包悬浮卡片*/
.person-popover-2 .ant-popover-inner-content {
    padding: 0!important;
}

.person-popover-2 .ant-popover-arrow {
    display: none!important;
}

.person-popover-2 .ant-popover-content {
    top: -31px!important;
}

.person-popover-2 .ant-popover-inner {
    box-shadow: unset!important;
}

/*创建模型，模型格式说明*/
.create-model-popover .ant-popover-inner-content {
    padding: 0!important;
}

.create-model-popover .ant-popover-arrow {
    display: none!important;
}

.create-model-popover .ant-popover-content {
    /*right: 6px!important;*/
}

.create-model-popover {
    top: 286px!important;
}

.create-model-popover .ant-popover-inner {
    border: 1px solid rgba(233,233,234,1)!important;
    box-shadow: 0px 2px 15px 0px rgba(193,193,193,0.5)!important;
}

/*xflow左侧卡片*/
.xflow-left-card-popover .ant-popover-inner-content {
    padding: 0!important;
}
.xflow-left-card-popover .ant-popover-inner {
    box-shadow: unset!important;
}

/*模型目录编辑页面*/
.model-catalog-edit-popover .ant-popover-inner-content {
    padding: 0!important;
}

.model-catalog-edit-popover .ant-popover-arrow {
    /*display: none!important;*/
}

.model-catalog-edit-popover .ant-popover-content {
    /*right: 6px!important;*/
}

.model-catalog-edit-popover {
    /*top: 286px!important;*/
}

.model-catalog-edit-popover .ant-popover-inner {
    /*border: 1px solid rgba(233,233,234,1)!important;*/
    /*border: 1px solid red!important;*/
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.09)!important;
}


/*模型目录编辑页面添加icon弹框*/
.model-catalog-edit-add-icon-popover .ant-popover-inner-content {
    padding: 0!important;
}

.model-catalog-edit-add-icon-popover .ant-popover-arrow {
    /*display: none!important;*/
}

.model-catalog-edit-add-icon-popover .ant-popover-content {
    /*right: 6px!important;*/
}

.model-catalog-edit-add-icon-popover {
    /*top: 286px!important;*/
}

.model-catalog-edit-add-icon-popover .ant-popover-inner {
    box-shadow: 0px 2px 15px 0px rgba(193,193,193,0.5)!important;
}

/*!*目录管理添加icon*!*/
.model-catalog-edit-add-icon-description-popover .ant-popover-inner-content {
    padding: 0!important;
}

.model-catalog-edit-add-icon-description-popover .ant-popover-arrow {
    /*display: none!important;*/
}

.model-catalog-edit-add-icon-description-popover .ant-popover-content {
    top: 10px!important;
}

.model-catalog-edit-add-icon-description-popover .ant-popover-inner {
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.09)!important;
}
/*实验页面*/
.experiment-popover .ant-popover-inner-content {
    padding: 0!important;
}

.experiment-popover .ant-popover-arrow {
    display: none!important;
}

.experiment-popover .ant-popover-content {
    top: -16px!important;
}

.experiment-popover .ant-popover-inner {
    box-shadow: 0px 2px 4px 0px rgba(223,223,223,0.5)!important;
}

/*搜索*/
.search-popover .ant-popover-inner-content {
    padding: 0!important;
}

.search-popover .ant-popover-arrow {
    display: none!important;
}

.search-popover .ant-popover-content {
    top: -6px!important;
}

.search-popover .ant-popover-inner {
    border-radius: 8px!important;
}
