.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #2554FF!important;
    background: rgba(37, 84, 255, 0.06)!important;
    font-weight: 400!important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    /*color: #4CAAB4!important;*/
    background: rgba(37, 84, 255, 0.06)!important;
    font-weight: 400!important;
}

.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    border-radius: 8px!important;
}

.ant-select-dropdown {
    border-radius: 8px!important;
    font-size: 14px!important;
}

/*多选框*/
.ant-select {
    font-size: 14px!important;
}
