.ant-table {
    border-radius: 16px!important;
}

.ant-table-column-sorters,
.ant-table-filter-column{
    justify-content: left!important;
}

.ant-table-column-title {
    flex: unset!important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: white!important;
}

/*表格*/
.ant-table-wrapper {
    border: unset!important;
}

.ant-table-thead > tr > th {
    background: #F9F9F9!important;
    color: #262626!important;
    border-bottom: unset!important;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 12px 8px!important;
}

.ant-table-tbody > tr > td {
    border-bottom: unset!important;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px!important;
}

.ant-table-filter-trigger {
    margin: 0 0 0 4px!important;
    padding: 0 5px!important;
}

.ant-table-filter-dropdown-btns {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}

.ant-table-filter-dropdown-btns .ant-btn {
    border-radius: 8px!important;
    margin-left: 6px!important;
    margin-right: 6px!important;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
    /*padding: 14px 8px!important;*/
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f3f6ff!important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px!important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px!important;
}

.ant-table-container {
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
}

.ant-table table {
    border-radius: 8px 8px 16px 16px!important;
}
