.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 400!important;
    background-color: unset!important;
    color: #4AA8B2!important;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
    color: inherit!important;
}

.my-model-select-type .ant-select-selection-item {
    display: flex!important;
    align-items: center!important;
}

.my-model-select-type .ant-select-selection-item svg{
    margin-left: 4px!important;
}

.my-model-select-type-dropdown {
    border: 1px solid rgba(233,233,234,1)!important;
    box-shadow: 0px 2px 15px 0px rgba(193,193,193,0.5)!important;
}

.my-model-select-type-dropdown .ant-cascader-menu {
    height: 327px!important;
}

.my-model-select-type-dropdown .ant-cascader-menu-item {
    transition: unset!important;
}

.my-model-select-type-dropdown ul:nth-child(1){
    width: 178px!important;
    padding: 21px 0 0 16px!important;
}

.my-model-select-type-dropdown ul:nth-child(2){
    width: 206px!important;
    padding: 21px 0 0 16px!important;
}

.my-model-select-type-dropdown-icon ul:last-child{
    width: 282px!important;
    padding: 64px 66px 0 16px!important;
    display: flex!important;
    flex-wrap: wrap!important;
    align-content: flex-start!important;
}

.my-model-select-type-dropdown-icon ul:last-child .ant-cascader-menu-item{
    padding: 0!important;
    margin: 0 8px 8px 0!important;
    width: 29px!important;
    height: 29px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    border-radius: 5px!important;
    color: #9EA1A8!important;
    border: 1px solid rgba(218,218,218,0.26)!important;
}

.my-model-select-type-dropdown-icon ul:last-child .ant-cascader-menu-item:hover{
    color: #4AA8B2!important;
    border: 1px solid rgba(74,168,178,1)!important;
}

.my-model-select-type-dropdown-icon ul:last-child .ant-cascader-menu-item .ant-cascader-menu-item-content{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}

.my-model-select-type-dropdown-icon ul:last-child:before {
    content: '选择模型icon' !important;
    position: absolute!important;
    top: 26px!important;
}

.my-model-select-type-dropdown-icon ul:last-child .ant-cascader-menu-item:hover {
    background-color: unset!important;
}
