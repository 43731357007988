/*多选框*/
.ant-checkbox-inner {
    width: 14px!important;
    height: 14px!important;
    border: 1px solid #e9e9e9!important;
}

/*表格的单选按钮*/
.ant-radio-inner {
    width: 14px!important;
    height: 14px!important;
}

.ant-radio-wrapper {
    margin-right: 46px!important;
}

.ant-upload.ant-upload-select-picture-card {
    width: auto!important;
    height: auto!important;
    margin-right: 0!important;
    margin-bottom: 0!important;
    border: unset!important;
    transition: unset!important;
}

/*notebook页面*/
.spin-row .ant-spin-container {
    display: flex;
}

.rc-virtual-list-scrollbar,
.rc-virtual-list-scrollbar-show {
    width: 5px!important;
}

.rc-virtual-list-scrollbar-thumb {
    background: rgba(50,50,50,.3)!important;
}
